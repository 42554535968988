.required-text {
	display: inline-block;
	padding-left: 8px;
	font-weight: 400;
	font-style: italic;
	font-size: 12px;
	color: red;
}

.drop-area {
	width: 100%;
	background-color: #fbfbfb;
	border: 0.07143em dashed;
	border-color: #bfbfbf;
	padding: 1.07143em;
	height: 10.71429em;
	text-align: center;
	margin-bottom: 2.14286em;

	.text {
		display: block;
		width: 100%;

		p {
			color: #9c9c9c;
			font-weight: 600;
			display: block;
			font-size: 24px;

			.icon.icon-upload {
				width: unset;
				height: unset;
				font-size: 50px;
			}
		}
	}
}