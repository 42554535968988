.theme-setting-panel{
	position: fixed;
	right: -250px;
	top: 150px;
	z-index: 500;
	width: 250px;
	display: none;
	@include transition(all 0.5s ease-in-out);
	.theme-setting-toggle{
		display: block;
		width: em(30px);
		height: em(30px);
		background: #fff;
		position: absolute;
		left: em(-30px);
		text-align: center;
		line-height: em(30px);
		border: 0;
		padding: 0;
		.text{
			display: none;
		}
		.icon{
			width: em(20px);
			height: em(20px);
		}
		@include mq(sm) {
			display: none;
		}
	}
	.card-theme{
		position: relative;
		background-color: #fff;
		border: 0;
		.card-header{
			h2{
				font-size: em(18px);
				color: $card_title_color;
				font-weight: $fontWeight-bold;
				margin: 0;
				display: inline-block;
			}
			.fa{
				display: none;
				margin-right: em(15px);
				@include mq(sm) {
					display: inline-block;
				}
			}
		}
		.card-body{

		}
		.color-value{
			display: block;
			.color-code{
				width: em(30px);
				height: em(30px);
				display: inline-block;
				vertical-align: top;
				background-color: #f1f1f1;
				margin: 0 em(03px);
				border: 0;
				@include border-radius(100px);
			}
		}
	}
	&.opened{
		right: 0;
	}
	@include mq(sm) {
		position: fixed;
		right: -100%;
		top: 0;
		width: 100%;
		height: 100%;
	}
}
// background images color theme
.imgbg{
	.dashboard-thumb{
		img{
			width: 100%;
			height: 100%;
		}
	}
	.header-container{
		background-color: transparent;
		border: 0;
	}
	.leftsidebar {
		.main-logo{
			//background-color: transparent;
		}
		.wrapper{
			background-color: transparent;
		}
	}
}

.text-report {
	font-style: italic;
	color: $green;
}