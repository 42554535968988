@import '../../public/styles/mixin.scss';
$height: 48px;
$heightMobile: 80px;

.wrapper {
	.wrapper-inner {
		.top-banner {
			width: 100%;
			height: $height;
			background-color: #f15a24;
			position: fixed;
			z-index: 9999;

			@include mq(sm) {
				height: $heightMobile;
				padding: 0 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
    	}

			.banner-text {
				text-align: center;
				color: white;
				font-weight: bolder;
				text-transform: uppercase;
				line-height: $height;
				font-size: 16px;

				@include mq(sm) {
					line-height: 20px;
    		}

				a {
					color: inherit;
					text-decoration: underline;
					@include mq(sm) {
						display: block;
    			}
				}
			}

			.btn-close {
				position: absolute;
				top: calc((#{$height} - 22px) / 2);
				right: 14px;
				font-size: 1em;
				background-color: transparent;
				padding: 0;
				border: 0;
				outline: 0;
				border-radius: 3px;
				display: inline-block;
				font-weight: 600;		
				font-family: inherit;
				cursor: pointer;

				img {
					display: block;
					height: 22px;
				}

				@include mq(sm) {
					top: 8px;
					right: 8px;
    		}
			}
		}

		.leftsidebar {
			&.banner-active {
				top: $height;

				@include mq(sm) {
					top: $heightMobile;
    		}
			}
		}

		.header-container.fixed {
			&.banner-active {
				top: $height;
				@include mq(sm) {
					top: $heightMobile;
    		}
			}
		}

		.main-content {
			&.banner-active {
				margin-top: $height;
				@include mq(sm) {
					margin-top: $heightMobile;
    		}
			}
		}
	}
}

.banner-enter {
  opacity: 0;
}

.banner-enter.banner-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.banner-leave.banner-leave-active {
  opacity: 0;
  transition: 300ms ease-in;
}