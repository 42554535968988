.breadcrumb {
	background-color: $breadcrumb_bg;
	padding: 0;
	margin: em(05px) 0 0 0;
	li{
		font-size: em(14px);
		color: $breadcurmb_text;
		font-weight: 600;
		line-height: normal;
		a{
			font-size: em(14px);
			color: $breadcurmb_text;
			font-weight: 600;
		}
		&.current{
			color: $base_color;
		}
	}
}