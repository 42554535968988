@import '../../../../../public/styles/variable.scss';
@import '../../../../../public/styles/mixin.scss';
.leagues{
	.organisation-logo{
		height: em(330px);
		border: 1px solid;
		border-color: #dedede;
		width: 100%;
		position: relative;
		.logo-wrap{
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: em(150px);
			height: em(150px);
			background-color: #f6f6f6;
			border: 1px solid;
			border-color: #efefef;
			color: #9e9e9e;
			font-weight: 600;
			@include border-radius(100px);
			text-align: center;
			line-height: em(150px);
		}
	}
}