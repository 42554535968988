@import '../../../../public/styles/mixin.scss';
.official-specific-availability{
	.fullcalendar{
		height: 800px;
		@include mq(sm) {
			height: 460px;
		}
		@include mq(md) {
			height: 500px;
		}
	}
	.fc-row {
		.fc-today{
			background-color: #fff;
		}
		&.fc-week{
			position: relative;
		}
		.fc-content-skeleton{
			height: 100%;
			z-index: 100;
			.fc-day-top{
				padding: 0;
			}
			table{
				width: 100%;
				height: 100%;
				position: relative;
			}
			thead{
				tr{
					td{
						position: relative;
						.fc-day-number{
							position: absolute;
							width: calc(100% - 10px);
							text-align: right;
							padding: 5px;
						}
					}
				}
			}
			tbody{
				tr{
					height: 24px;
					td{
						position: relative;
						vertical-align: middle;
						.fc-day-grid-event{
							border: 0;
							border-radius: 0;
							font-size: em(16px);
							font-weight: 700;
							width: calc(100% - 6px);
							display: block;
							&.fc-event{
								display: block;
							    text-align: center;
							}
							.fc-title{
								@include mq(sm) {
									display: none;
								}
								@include mq(md) {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
.unavailable{
	background-color: #fff6f1;
	color: #ff3900;
	&.fc-day-grid-event{
		background-color: transparent;
		.fc-title{
			color: #ff3900;
		}
	}
}
.available{
	background-color: #eff9e6;
	color: #75c800;
	&.fc-day-grid-event{
		background-color: transparent;
		.fc-title{
			color: #75c800;
		}
	}
}
.notspecified{
	background-color: #f7f7f7;
	color: #808180;
	&.fc-day-grid-event{
		background-color: transparent;
		.fc-title{
			color: #808180;
		}
	}
}
.upconfirmed{
	background-color: #fff4e3;
	color: #ff9800;
	&.fc-day-grid-event{
		background-color: transparent;
		.fc-title{
			color: #ff9800;
		}
	}
}

.available-days{
	.boxes{
		padding: 0;
		border: 1px solid;
		border-color: #d1d1d1;
	}
	.col{
		margin-bottom: 30px;
		width: 14.2857%;
		@include mq(lg) {
			width: 25%;
			flex-grow: 0;
			flex-basis: inherit;
		}
		@include mq(md) {
			width: 50%;
			flex-grow: 0;
			flex-basis: inherit;
		}
		@include mq(sm) {
			width: 50%;
			flex-grow: 0;
			flex-basis: inherit;
		}
		@include mq(xs) {
			width: 100%;
			flex-grow: 0;
			flex-basis: inherit;
		}
	}
	.head{
		display: block;
		text-align: center;
		background-color: #f2f2f2;
		font-size: em(14px);
		text-transform: uppercase;
		color: #646464;
		font-weight: 700;
		padding: 15px;
		line-height: 1;
	}
	.box-color{
		display: flex;
		flex-wrap: wrap;
		padding: 15px;
		.wrap{
			height: 150px;
			overflow: auto;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-content: center;
			align-items: center;
		}
		span{
			font-size: em(14px);
			color: #fff;
			font-weight: 600;
			display: block;
			line-height: 1;
		}
		&.green{
			background-color: #6ac500;
		}
		&.red{
			background-color: #ff2000;
		}
		&.orange{
			background-color: #ff9000;
			.wrap{
				align-content: inherit;
				span{
					margin: 5px 0px;
				}
			}
		}
		@include mq(md) {
			height: auto;
		}
		@include mq(sm) {
			height: auto;
		}
	}
}