@import '../../../../../public/styles/mixin.scss';
.ability{
	.actions{
		display: flex;
		width: 100%;
		ul{
			&.list-inline{
				width: 100%;
				li{
					display: inline-block;
					vertical-align: top;
					margin-right: 15px;
					margin-bottom: 15px;
					@include mq(sm) {
						margin-right: 0;
						display: block;
						width: 100%;
					}
					&:last-child{
						margin-right: 0px;
					}
					.input-group{
						width: auto;
						@include mq(sm) {
							width: 100%;
						}
						.input-group-text{
							border-color: #e6e6e6;
							background-color: #f7f7f7;
						}
						.text{
							background-color: #fff;
							border: 1px solid #e6e6e6;
							padding: 6px 12px;
							font-weight: 600;
							flex: 1;
							width: 1%;
							@include border-right-radius(3px);
							a{
								color: #212529;
							}
						}
					}
				}
			}
		}
	}
	.table-area{
		.table{
			width: auto;
		}
	}
	table{
		width: auto;
		thead{
			tr{
				th{
					text-transform: uppercase;
					padding: 10px;
					text-align: center;
					&:first-child{
						text-align: left;
					}
				}
			}
		}
		tbody{
			tr{
				td{
					padding: 10px;
					text-align: center;
					position: relative;
					.ptext{
						display: block;
						margin-bottom: 10px;
						text-align: left;
					}
					.input-group{
						width: auto;
						.input-group-text{
							border-color: #e6e6e6;
							background-color: #fff;
						}
						.text{
							background-color: #fff;
							border: 1px solid #e6e6e6;
							padding: 6px 12px;
							font-weight: 600;
							color: #999999;
							@include border-right-radius(3px);
							a{
								color: #999;
							}
							@include mq(sm) {
								margin-top: 10px;
							}
						}
					}
					.icon{
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						margin: auto;
						display: table;
						width: 20px;
						height: 20px;
						cursor: pointer;
					}
				}
			}
		}
	}
}