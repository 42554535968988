.ReactTable{
    .rt-tbody{
        .rt-td{
            .check-box{
                margin-left: 5px;
            }
        }
    }
}

.badge {
    border: transparent !important;
    outline: 0;
}