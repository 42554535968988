.official-dashboard{
	.fullcalendar{
		.fc-toolbar{
			@media (max-width: 1500px){
				.fc-center{
					clear: both;
					display: table;
					width: 100%;
					text-align: center;
					h2{
						display: block;
						float: none;
					}
				}
			}
		}
	}
}