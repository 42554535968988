$themes: (
  defaults: (
    leftsidebarBg: $leftsidebar_bg,
  ),
  oranges: (
    leftsidebarBg: #ff4b00,
  ),
  browns: (
    leftsidebarBg: #fff,
  ),
);