@import './variable.scss';
@import './mixin.scss';
.status-color {
    width: em(12px);
    height: em(12px);
    @include border-radius(100%);
    display: inline-block;
    cursor: pointer;

    &.green {
        background-color: $status_green;
    }

    &.red {
        background-color: $status_red;
    }

    &.gray {
        background-color: $status_gray;
    }

    &.blue {
        background-color: $status_blue;
    }

    &.yellow {
        background-color: $status_yellow;
    }
}