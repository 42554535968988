@import '../../../../public/styles/mixin.scss';
$cellWidth: 45px;
$cellHeight: 32px;
$eventHeight: 21px;

.available-official {
	min-height: 70vh;
	.date-box-available {
		@include max-width(458px) {
			margin-bottom: 4px;
		}

		.date-text {
			font-size: 14px;
			font-weight: 600;
			color: rgba(0, 0, 0, 0.75);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-date {
			color: rgba(0, 0, 0, 0.75);
			cursor: pointer;
		}
	}

	.time-text {
		border: 1px solid #dcdcdc;
		padding: 2px 6px;
		border-radius: 4px;
		cursor: pointer;
		font-weight: 600;
	}
}

.react-datepicker-popper {
	z-index: 10 !important;
}

.charts-table {
	display: flex;
	
	table, td {
		border-collapse: collapse;
		border: 1px solid #e9e9e9;
		color: rgba(0, 0, 0, 0.75);
	}

	.side-table {
		flex-shrink: 0;
		td {
			height: $cellHeight;
		}

		.title {
			font-size: 12px;
			text-align: center;
		}

		.official {
			padding-left: 8px;
			padding-right: 8px;
			font-weight: 600;
		}
	}

	.content-table {
		flex-shrink: 0;
		flex-grow: 1;
		overflow: auto;
		width: 1px;
		position: relative;

		.table-front {
			position: absolute;
			bottom: 0;
			z-index: 9;

			table, td {
				border: 0;
			}

			td {
				padding: 0;
				height: $cellHeight;
			}

			.event-container {
				position: relative;
				height: 100%;

				.event {
					margin-top: 5px;
					position: absolute;
					top: 0;
					height: $eventHeight;
					background-color: #d63031;
					border-radius: 30px;
				};
			}
		}

		.table-bg {
			position: absolute;
			top: 0;

			table {
				border-left: 0;
			}

			td {
				min-width: $cellWidth;
				height: $cellHeight;
			}

			thead {
				td {
					text-align: center;
					&:first-child {
						border-left: 0;
					}
				}
			}

			tbody {
				tr {
					td:first-child {
						border-left: 0;
					}
				}
			}

			.title {
				font-size: 12px;
			}
		}
	}
}