@import './variable.scss';
@import './mixin.scss';
.fullcalendar{
	.fc-button{
		padding: em(06px) em(15px);
		height: auto;
		line-height: normal;
		font-weight: 700;
		box-shadow: none;
		text-transform: capitalize;
		text-shadow: none;
		border: em(01px) solid;
		font-size: em(14px);
		opacity: 1;
		@include border-radius(3px);
		&.fc-state-default{
			color: #f2794e;
			background-color: #feeee9;
			border-color: #feeee9;
			background-image: none;
		}
		&.fc-state-active{
			color: #fff;
			background-color: #f15a24;
			border-color: #f15a24;
		}
		&.fc-today-button{
			color: #fff;
			background-color: #f15a24;
			border-color: #f15a24;
		}
		&.fc-prev-button{
			background-color: #f4f4f4;
			width: em(30px);
			height: em(30px);
			border-color: #f4f4f4;
			padding: 0;
			border-radius: 0;
			border-right-color: #e5e5e5;
			color: #afafaf;
			@include border-left-radius(3px);
			&:focus{
				outline: none;
				box-shadow: none;
			}
		}
		&.fc-next-button{
			background-color: #f4f4f4;
			width: em(30px);
			height: em(30px);
			border-color: #f4f4f4;
			padding: 0px;
			border-radius: 0;
			color: #afafaf;
			@include border-right-radius(3px);
			&:focus{
				outline: none;
				box-shadow: none;
			}
		}
		&:focus{
			outline: none;
			box-shadow: none;
		}
	}
	.fc-toolbar{
		&.fc-header-toolbar{
			.fc-center{
				h2{
					font-size: em(18px);
					font-weight: 700;
					color: #4d4d4d;
				}
				@include mq(sm) {
					display: table;
					width: 100%;
					text-align: center;
				}
				@include mq(md) {
					display: table;
					width: 100%;
				}
			}
			.fc-right{
				.fc-button{
					margin: 0 em(05px);
				}
				@include mq(sm) {
					float: right;
				}
				@include mq(x) {
					margin-left: em(-05px);
					margin-bottom: em(10px);
				}
			}
			.fc-left{
				margin-bottom: em(10px);
				.fc-button-group>*{
					margin: 0px;
				}
			}
		}
	}
	.fc-head{
		.fc-day-header{
			background-color: #eaeaea;
			font-size: em(18px);
			color: #6f6f6f;
			font-weight: 600;
			padding: em(15px) 0;
			line-height: normal;
			@include mq(sm) {
				font-size: em(12px);
			}
		}
	}
	.fc-body{
		.fc-day-top{
			padding: em(10px) em(15px);
			@include mq(sm) {
				padding: em(10px) em(05px);
			}
		}
	}
}