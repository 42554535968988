@import '../../../../../public/styles/mixin.scss';

.league-edit{
	.action{
		.btn{
			margin-right: 15px;
			&:last-child{
				margin-right: 0px;
			}
			@include mq(sm) {
				margin-right: 0px;
			}
		}
	}
}