@import '../../../../public/styles/variable.scss';
@import '../../../../public/styles/mixin.scss';
.official-game{
	padding: 0;
	margin: 0;
	.action{
		display: block;
		.btn{
			margin-right: em(20px);
			&:last-child{
				margin-right: 0;
			}
		}
	}

	.btn{
		&.btn-check{
			width: em(30px);
			height: em(30px);
			line-height: em(35px);
			background-color: #19c245;
			margin-right: em(12px);
			padding: 0;
			@include border-radius(100%);
			.icon-check{
				width: em(16px);
				height: em(12px);
				svg{
					width: 100%;
					height: 100%;
					polygon {
						fill: #fff;
					}
				}
			}
		}
		&.btn-cross{
			width: em(30px);
			height: em(30px);
			line-height: em(35px);
			background-color: #ff474b;
			padding: 0;
			@include border-radius(100%);
			.icon-cross{
				width: em(10px);
				height: em(10px);
				svg{
					width: 100%;
					height: 100%;
					polygon {
						fill: #fff;
					}
				}
			}
		}
	}
}