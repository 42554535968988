@import '../../../../../public/styles/status-color.scss';
.game-detail{
	.games{
		.table-data{
			border: 1px solid #ededed;
			th{
				background-color: #fbfbfb;
				font-size: em(16px);
				font-weight: 600;
				text-transform: none;
				border: 1px solid #ededed;
				color: #626262;
			}
			td{
				background-color: #fff;
				font-size: em(16px);
				font-weight: 600;
				text-transform: none;
				border: 1px solid #ededed;
				padding: 0 em(10px);
				color: #626262;
			}
		}
		.map{
			width: 100%;
			height: 100%;
			iframe{
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
	.asign-game{
		h2{
	        display: block;
	        margin-bottom: em(12px);
	        font-size: 24px;
	        color: $text_color;
	        font-weight: 600;
	    }
	    .wrap{
	    	background-color: #fafafa;
	    	border: 1px solid;
	    	border-color: #ededed;
	    	padding: em(30px) em(15px) em(20px);
	    }
	    .list-items{
	    	border-bottom: 1px solid;
	    	border-color: #dddddd;
	    	padding-bottom: em(10px);
	    	margin-bottom: em(15px);
	    	.post{
	    		font-size: em(16px);
	    		color: #4d4d4d;
	    		font-weight: 600;
	    		@include mq(lg) {
					display: block;
	    		}
	    	}
	    	.name-status{
	    		.status{
	    			display: inline-block;
	    			@extend .status-color;
	    		}
	    		.name{
	    			display: inline-block;
	    			vertical-align: middle;
	    			font-size: em(16px);
	    			color: #626262;
	    			line-height: 1;
	    			padding-left: 10px;
	    			width: calc(100% - 12px);
	    			.name{
	    				display: inline-block;
	    			}
	    			.surname{
	    				display: inline-block;
	    				margin-left: em(02px);
	    			}
	    		}
	    	}
	    }
	}
}

.button-link {
	background-color: transparent;
	padding: 0;
	border: 0;
	border-radius: 0px;
	color: #f15a24;
	font-weight: 600;
	font-size: 1em;
	cursor: pointer;
}