.general-modal{
    .form-group{
        .btn-add{
            font-size: 16px;
            color: #f15a24;
            font-weight: 600;
            background-color: transparent;
            padding: 0;
            border: 0;
            cursor: pointer;
        }
        label{
            display: block;
            width: 100%;
            position: relative;
            .delete{
                position: absolute;
                right: 0;
                top: 0;
            }
            .btn-text-link{
                &:focus{
                    color: #f15a24;
                }
            }
        }
    }
    .modal-body{
        max-height: 350px;
        overflow: auto;
    }
}
