.organisation-pattern-form{
	.form-group{
		.btn-text-link{
			&.delete{
				position: absolute;
				right: 0;
				top: 3px;
				font-size: 14px;
			}
		}
	}
}

.pattern-modal{
	.modal-body{
		max-height: 350px;
		overflow: auto;
	}
}