@import '../../../../public/styles/edit-photo.scss';
.official-edit-form{
	padding-bottom: em(30px);
	border-bottom: em(01px) solid;
	border-color: $hr_color;
	margin-bottom: em(50px);
    .edit-photo{
        @extend .edit-photo;
    }
    .form{
        .form-group{
            .custom-checkbox{
                margin-top: em(05px);
            }
        }
    }
    .action{
        .dflex{
            @include mq(sm) {
                display: block;
            }
        }
    }
}
.allocated-match-info{
    padding: 0;
    margin: 0;
    h2{
        display: block;
        margin-bottom: em(12px);
        font-size: 24px;
        color: $text_color;
        font-weight: 600;
    }
}
.modal#editPhoto{
    .bulk-import {
        .titles{
            display: none;
        }
    }
    .modal-body{
        -webkit-border-bottom-right-radius: em(05px);
        -webkit-border-bottom-left-radius: em(05px);
        -moz-border-radius-bottomright: em(05px);
        -moz-border-radius-bottomleft: em(05px);
        border-bottom-right-radius: em(05px);
        border-bottom-left-radius: em(05px);
    }
}