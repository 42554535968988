@import '../../../../public/styles/mixin.scss';
.invoices-page{
	.box{
		.account-balance{
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 700;
			color: #1f1f1f;
			@include mq(sm) {
				margin-bottom: 20px;
			}
			@include mq(md) {
				margin-bottom: 20px;
			}
			label{
				@include mq(sm) {
					margin-top: 20px;
				}
			}
		}
		.btn{
			&.btn-subscription{
				background-color: #fff;
				color: #1f1f1f;
				font-size: 14px;
				border: 1px solid #e5e5e5;
				&.active{
					background-color: #f15a24;
					color: #fff;
				}
				@include mq(sm) {
					width: 100%;
					margin-bottom: 15px;
				}
			}
		}
		.actions{
			.btn-group{
				@include mq(sm) {
					display: block;
				}
			}
		}
	}
}
.modal{
	&.usm{
		.your-payment-info{
			background-color: #f6f6f6;
			font-size: 18px;
			color: #3b3b3b;
			font-weight: 700;
			padding: 18px 30px;
			margin: 0 -30px 30px;
		}
		.credit-card{
			margin-top: 8px;
		}
	}
}