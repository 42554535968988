@import '../../../../public/styles/mixin.scss';
.payment-info {
	.box {
		.btn {
			&.btn-subscription {
				background-color: #fff;
				color: #1f1f1f;
				font-size: em(14px);
				border: 1px solid #e5e5e5;
				&.active{
					background-color: #f15a24;
					color: #fff;
				}
				@include mq(sm) {
					width: 100%;
					margin-bottom: 15px;
				}
			}
		}
		.actions {
			.btn-group {
				@include mq(sm) {
					display: block;
				}
			}
		}	
		.current-plan {
			border: 1px solid #e5e5e5;
			.gray {
				background-color: #eeeeee;
				font-size: em(16px);
				color: #4d4d4d;
				text-transform: uppercase;
				font-weight: 700;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				padding: 15px;
			}
			table {
				margin-bottom: 0;
				border: 0;
				tr {
					th {
						border: 0px;
						text-transform: uppercase;
					}
					td {
						border: 0px;
					}
				}
			}
		}
		.credit-card-info {
			padding: 30px;
			border: 1px solid #e2e2e2;
			.credit-card {
				display: block;
				margin-top: 8px;
			}
			.save {
				.btn {
					margin-top: 35px;
					@include mq(sm) {
						margin-top: 0px;
					}
				}
			}
			h2 {
				line-height: 1.042;
				font-size: 22px;
				font-weight: 700;
				color: rgb(77, 77, 77);
				display: block;
				margin-bottom: 14px;
			}
		}
	}

	.card-detail {
		margin-bottom: 56px;
		p {
			margin-bottom: 6px;
			font-size: 14px;
			span.title-name {
				display: inline-block;
				width: 120px;
				font-weight: 600;
				@include mq(sm) {
					width: 100%;
					display: block;
				}
			}
			span.divide {
				display: inline-block;
				width: 14px;
				@include mq(sm) {
					display: none;
				}
			}

			span.dot-text {
				letter-spacing: 3px;
			}

			span.card-number {
				letter-spacing: 0.8px;
			}
		}
	}
}
