.features-page {
	position: relative;
	
	.loader {
		height: 87vh;
	}

	.logo-icon {
		width: 35%;
		display: block;
		margin: 0 auto;
	}

	.box {
		border-radius: 10px;
		p {
			font-size: 16px;
    	color: #9c9c9c;
    	font-weight: 600;
    	line-height: normal;
		}
	}
}