@import '../../../../public/styles/mixin.scss';
.subscription {
	.box {
		.btn {
			&.btn-subscription {
				background-color: #fff;
				color: #1f1f1f;
				font-size: 14px;
				border: 1px solid #e5e5e5;
				&.active {
					background-color: #f15a24;
					color: #fff;
				}
				@include mq(sm) {
					width: 100%;
					margin-bottom: 15px;
				}
			}
		}
		.actions {
			.btn-group {
				@include mq(sm) {
					display: block;
				}
			}
		}
	}

	.details {
		h2 {
			line-height: 1.042;
			font-size: 24px;
			font-weight: 700;
			color: #4d4d4d;
			display: block;
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 6px;
			font-size: 14px;
			span:first-child {
				display: inline-block;
				width: 150px;
				font-weight: 600;
				@include mq(sm) {
					width: 100%;
					display: block;
				}
			}
			span.divide {
				display: inline-block;
				width: 14px;
				@include mq(sm) {
					display: none;
				}
			}
		}
	}
}