.wrapperCustomEditor {
	margin-bottom: 16px;
}

.draftCustomEditor {
	border: 1px solid #dcdcdc;
	padding: 0 16px;
	height: 320px;
	overflow: auto;

	.DraftEditor-root {
		height: auto;
	}
}