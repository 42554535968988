@import '../../../../public/styles/variable.scss';
.permission{
	padding: 0;
	.action{
		display: block;
		text-align: center;
		border-top: em(01px) solid;
		border-color: $hr_color;
		padding-top: em(18px);
	}
	.subtitle{
		font-size: em(16px);
		color: $text_color;
		font-weight: 600;
		display: block;
		margin-bottom: em(15px);
		line-height: normal;
	}
}