@import '../../../../../public/styles/variable.scss';
.edit-game-page{
	.edit-game{
		position: relative;
	}
	.assign-edit{
		position: relative;
		h2{
			color: #4d4d4d;
			display: block;
			margin-bottom: em(10px);
		}
		.wrap{
			background-color: #fafafa;
			border: 1px solid;
			border-color: #eaeaea;
			padding: em(30px) em(20px) em(10px);
		}
		.list-item{
			border-bottom: 1px solid;
			border-color: #d9d9d9;
			padding-bottom: em(10px);
			margin-bottom: em(30px);
			font-size: em(16px);
			.normal-select{
				border: 0px;
				height: auto;
				padding: em(0px) em(35px) em(0px) em(0px);
				background-color: transparent;
				max-width: 250px;
				width: 100%;
				background-position: 105% center;
			}
			.post{
				font-weight: 600;
				color: #4d4d4d;
				white-space: nowrap;
				margin-bottom: 5px;
			}
		}
	}
}