.custom-reports {
	.box {
		h1 {
			font-size: 1.4em;
			color: #4d4d4d;
			font-weight: 700;
			line-height: normal;
			margin: 0;	
		}

		.label {
			display: flex;
			align-items: center;
		}
	}

	.required-text {
		display: inline-block;
		padding-left: 8px;
		font-weight: 400;
		font-style: italic;
		font-size: 12px;
		color: red;
	}

	.drop-area {
		width: 100%;
		background-color: #fbfbfb;
		border: 0.07143em dashed;
		border-color: #bfbfbf;
		padding: 1.07143em;
		height: 10.71429em;
		text-align: center;
		margin-bottom: 2.14286em;

		.text {
			display: block;
			width: 100%;

			p {
				color: #9c9c9c;
				font-weight: 600;
				display: block;
				font-size: 24px;

				.icon.icon-upload {
					width: unset;
					height: unset;
					font-size: 50px;
				}
			}
		}
	}
}

.report-icon {
	cursor: pointer;
	color: #f15a24;
}

.line-btn {
	background-color: transparent;
	padding: 0;
	border: 0;
	border-radius: 0px;
	color: #f15a24;
	font-weight: 600;
	font-size: 1em;
	cursor: pointer;
}

.timepicker.input-group {
	border: 1px solid;
  border-color: #dcdcdc;
}

.rc-time-picker .rc-time-picker-input {
	background-color: #fff;
	font-size: 1em;
	color: #8c8c8c;
	height: 2.85714em;
	padding: 0.85714em 1em;
	position: relative;
	border: 0;
	border-radius: 3px;
	-webkit-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}