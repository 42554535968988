@import '../../../../public/styles/edit-photo.scss';
@import '../../../../public/styles/flex.scss';

.edit-profile {
	.edit-photo {
		@extend .edit-photo;
	}
	.action {
		.first {
			@include mq(sm) {
				@include order(2);
			}
			@include mq(md) {
				@include order(2);
			}
    }
  }
}